import { useEffect, useRef, useState, useCallback } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import {
  RapierRigidBody,
  RigidBody,
  quat,
  CollisionEnterPayload,
  CollisionExitPayload,
  useRapier,
  CuboidCollider,
} from '@react-three/rapier'
import * as THREE from 'three'
// import * as dat from 'lil-gui'
import {
  availableInteractableObjectAtom,
  currentInteractionAtom,
  playerLocationAtom,
  currentInteractionLocationAtom,
  lastBiancaLocationAtom,
  insideHouseAtom,
  biancaLoadedAtom,
  activeMobileDirectionsAtom,
  activeMobileSprintAtom,
  currentJobProjectAtom,
  biancaBeingAnimatedAtom,
  animatingSlideAtom,
  loadingAtom,
  gameStartedAtom,
  loadingScreenClosedAtom,
  DEFAULT_POSITION,
  DEFAULT_ROTATION,
} from '../../store/store'
import { useAtom } from 'jotai'
import {
  BiancaAnimationName,
  InteractionTypeEnum,
  SceneChild,
  PositionToSave,
} from '../../types/portfolio_types'
import { determineYawToPointTo, shouldSpinYawClockwise } from '../../helpers/helperFunctions'
import { deepDispose } from '../../helpers/deepDispose'
import { interactable_bianca_objects } from '../../interactable_data/bianca_data'
import { interactable_world_objects } from '../../interactable_data/world_data'
import emitter from '../../helpers/MittEmitter'
import useFaceManager from '../../hooks/FaceManager'

type MovementDirection =
  | 'forward'
  | 'backward'
  | 'leftward'
  | 'rightward'
  | 'backward_right'
  | 'forward_right'
  | 'backward_left'
  | 'forward_left'

const PRIMARY_IDLE_ANIMATION = 'bianca_idle'
const SECONDARY_IDLE_ANIMATION = 'bianca_looking_around'

const animation_speeds: { [key: string]: number } = {
  bianca_run: 1.4,
  bianca_walk: 1.1,
}

const small_obstacles: { [key: string]: number } = {
  button_base: 0.2,
  red_button: 0.2,
  stair001: 0.55,
  stair002: 0.55,
  stair003: 0.55,
  stair004: 0.55,
  stair005: 0.55,
}

const MAX_MIDAIR_VEL = 500
const MAX_FULL_SPIN_IN_PLACE_TIME = 2500
const MAX_FULL_SPIN_TIME = 3500
const BIANCA_ID = 'bianca_rig'

const Bianca = () => {
  const bianca_model = useGLTF('/models/portfolio_forest/bianca.glb')
  const animations = useAnimations(bianca_model.animations, bianca_model.scene)
  const three = useThree()
  const camera = three.camera
  const [sceneChildren, setSceneChildren] = useState<SceneChild[]>([])
  const bianca_face_material_ref = useRef<THREE.MeshStandardMaterial | null>(null)
  useFaceManager({
    id: 'bianca',
    face_name: 'bianca',
    default_face: 'bianca neutral',
    face_material: bianca_face_material_ref.current,
    min_filter: THREE.LinearFilter,
  })

  const [interactableObject] = useAtom(availableInteractableObjectAtom)
  const [currentInteractionObj, setCurrentInteractionObj] = useAtom(currentInteractionAtom)
  const [playerLocationObj, setPlayerLocationObj] = useAtom(playerLocationAtom)
  const [currentInteractionLocationObj, setCurrentInteractionLocation] = useAtom(
    currentInteractionLocationAtom
  )
  const [lastBiancaLocation, setLastBiancaLocation] = useAtom(lastBiancaLocationAtom)
  const [insideHouse] = useAtom(insideHouseAtom)
  const [biancaLoaded, setBiancaLoaded] = useAtom(biancaLoadedAtom)
  const [biancaBeingAnimated, setBiancaBeingAnimated] = useAtom(biancaBeingAnimatedAtom)
  const [animatingSlide, setAnimatingSlide] = useAtom(animatingSlideAtom)
  const [currentJobProject] = useAtom(currentJobProjectAtom)
  const [loading] = useAtom(loadingAtom)
  const [gameStarted] = useAtom(gameStartedAtom)
  const [loadingScreenClosed] = useAtom(loadingScreenClosedAtom)

  const { world, rapier } = useRapier()

  const defalt_location_set = useRef(false)
  const bianca_physics_body = useRef<RapierRigidBody | null>(null)
  const [activeMobileDirectionsObj] = useAtom(activeMobileDirectionsAtom)
  const [activeMobileSprint] = useAtom(activeMobileSprintAtom)
  const [currentAction, setCurrentAction] = useState<THREE.AnimationAction>()
  const lookingDirection = useRef<MovementDirection>('backward')
  const smoothed_camera_position = useRef(new THREE.Vector3(0.16, 14.44, 25.85))
  const smoothed_camera_target = useRef(new THREE.Vector3())
  const [obstacleToWalkOver, setObstacleToWalkOver] = useState<THREE.Object3D | null>(null)
  const [interactCameraPosition, setInteractCameraPosition] = useState<THREE.Vector3 | null>(null)
  const target_yaw = useRef<number | null>(null)
  const spin_to_face_interact_clockwise = useRef(false)
  const is_midair = useRef(false)
  const current_idle_animation = useRef<BiancaAnimationName>(PRIMARY_IDLE_ANIMATION)
  const can_rotate_in_place = useRef(true)
  const idle_animation_timeout = useRef<null | NodeJS.Timeout>(null)
  const spin_in_place_stuck_timeout = useRef<null | NodeJS.Timeout>(null)
  const spin_total_timeout = useRef<null | NodeJS.Timeout>(null)
  const can_focus_on_interactable = useRef(false)

  const frame_vel = useRef(new THREE.Vector3())
  const frame_rotation = useRef(new THREE.Vector3())

  const kForward = useRef(false)
  const kBackward = useRef(false)
  const kLeftward = useRef(false)
  const kRightward = useRef(false)
  const kSprint = useRef(false)

  const changeAction = useCallback(
    (animation_name: BiancaAnimationName, play_once?: boolean) => {
      const currentClip = currentAction?.getClip()

      if (currentAction && currentClip && currentClip.name !== animation_name) {
        const fadeDuration = 0.5
        currentAction.fadeOut(fadeDuration)

        // It's necessary to always reset the animation when using fadeIn/fadeOut
        const animation = animations.actions[animation_name]!.reset().fadeIn(fadeDuration).play()
        if (play_once) animation.loop = THREE.LoopOnce
        animation.timeScale = animation_speeds[animation_name] || 1

        setCurrentAction(animation)
      }
    },
    [currentAction, animations]
  )

  const isMidair = (collision_name?: string) => {
    if (!bianca_physics_body.current || collision_name === 'slide_platform') return false

    const origin_bianca_bottom = bianca_physics_body.current.worldCom()
    origin_bianca_bottom.y -= 1

    // Rotation gives a "this is undefined" error if invoked while destructured
    const rotation = bianca_physics_body.current.rotation()
    const direction = { x: 0, y: -1, z: 0 }

    // Get the shape: Shapes can't be modified and we can't use Bianca's shape because it would trigger
    // horizontal collisions, we need to use the same values but scaled * 0.75 so we only detect collisions downwards.
    // You can log Bianca's shape to see which values you'd need to use in your custom shape
    // const bianca_shape = bianca_physics_body.current.collider(0).shape
    const adjusted_shape = new rapier.Cuboid(0.75, 1.2, 0.75)

    const hit = world.castShape(origin_bianca_bottom, rotation, direction, adjusted_shape, 0.1, 10, false)
    const is_midair = !hit || (hit && hit.time_of_impact > 0.45)

    return is_midair
  }

  const jump = (prepare_for_jump: boolean, power?: number) => {
    if (!bianca_physics_body.current) return

    // Animate jump
    setBiancaBeingAnimated(true)
    const DEFAULT_JUMP_POWER = 580

    if (prepare_for_jump) {
      const switchToMidJump = () => {
        // Non-looping animations (bianca_preparing_jump) don't fade well, so some tweaking was needed to make it look passable
        // Next time use only looping ones.
        const fadeDuration = 0.4
        currentAction!.fadeOut(0.1)
        const animation = animations.actions.bianca_mid_jump!.reset().fadeIn(fadeDuration).play()
        setCurrentAction(animation)

        bianca_physics_body.current!.applyImpulse({ x: 0, y: power || DEFAULT_JUMP_POWER, z: 0 }, true)
        is_midair.current = true
        animations.mixer.removeEventListener('finished', switchToMidJump)
      }

      changeAction('bianca_preparing_jump', true)
      animations.mixer.addEventListener('finished', switchToMidJump)
    } else {
      is_midair.current = true
      bianca_physics_body.current.applyImpulse({ x: 0, y: power || DEFAULT_JUMP_POWER, z: 0 }, true)
    }
  }

  useEffect(() => {
    setCurrentAction(animations.actions.bianca_idle!.play())

    const processedSceneChildren = bianca_model.scene.children.map((child) => {
      return {
        object: child,
        element: <primitive object={child} key={child.id} />,
      }
    })

    setSceneChildren(processedSceneChildren)

    const bianca_face_material = bianca_model.materials.bianca_face as THREE.MeshStandardMaterial

    if (bianca_face_material.map) {
      bianca_face_material_ref.current = bianca_face_material
    }
  }, [bianca_model, animations, camera])

  // Set the default position and rotation, also set bianca loaded here
  useEffect(() => {
    if (!defalt_location_set.current && !biancaLoaded && bianca_physics_body.current) {
      const entered_house_position = new THREE.Vector3(2, 1.5, 10)
      const default_position = insideHouse ? entered_house_position : lastBiancaLocation.position

      const default_rotation = insideHouse
        ? new THREE.Quaternion().setFromEuler(new THREE.Euler(0, Math.PI, 0, 'XYZ'))
        : lastBiancaLocation.rotation

      bianca_physics_body.current.setTranslation(default_position, true)
      bianca_physics_body.current.setRotation(default_rotation, true)

      setBiancaLoaded(true)
      defalt_location_set.current = true
      setTimeout(() => {
        can_focus_on_interactable.current = true
      }, 2300)

      if (!insideHouse && !currentJobProject) {
        setLastBiancaLocation({
          position: DEFAULT_POSITION,
          rotation: DEFAULT_ROTATION,
        })
      }
    }
  })

  // Keep children in scene
  useEffect(() => {
    const children_not_in_scene = sceneChildren
      .map((sceneChild) => sceneChild.object)
      .filter((obj) => !bianca_model.scene.children.find((child) => child.id === obj.id))

    bianca_model.scene.children.push(...children_not_in_scene)
  }, [bianca_model, sceneChildren])

  // Listeners the slide down animation
  useEffect(() => {
    const slideDown = () => {
      setAnimatingSlide(true)

      bianca_physics_body.current!.applyImpulse({ x: -300, y: 0, z: 0 }, true)
      changeAction('bianca_sliding_down', true)

      // I didn't find animations.mixer.removeEventListener('finished', onSlideAnimationFinished) to be that accurate.
      // Good ol' timeout gets the job done
      setTimeout(() => {
        setAnimatingSlide(false)
      }, 2200)
    }

    emitter.on('execute_slide_animation', slideDown)

    return () => {
      emitter.off('execute_slide_animation')
    }
  }, [changeAction, setAnimatingSlide])

  // Listeners for movement
  // The above use effect runs every time changeAction changes, so basically
  // on run-walk animation transitions. That's why I put these listeners in
  // their own use effect.
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const key = e.key.toLowerCase()

      if (!kForward.current && (key === 'arrowup' || key === 'w')) {
        kForward.current = true
      } else if (!kLeftward.current && (key === 'arrowleft' || key === 'a')) {
        kLeftward.current = true
      } else if (!kBackward.current && (key === 'arrowdown' || key === 's')) {
        kBackward.current = true
      } else if (!kRightward.current && (key === 'arrowright' || key === 'd')) {
        kRightward.current = true
      } else if (!kSprint.current && key === 'shift') {
        kSprint.current = true
      }
    }

    const handleKeyUp = (e: KeyboardEvent) => {
      const key = e.key.toLowerCase()

      if (key === 'arrowup' || key === 'w') {
        kForward.current = false
      } else if (key === 'arrowleft' || key === 'a') {
        kLeftward.current = false
      } else if (key === 'arrowdown' || key === 's') {
        kBackward.current = false
      } else if (key === 'arrowright' || key === 'd') {
        kRightward.current = false
      } else if (key === 'shift') {
        kSprint.current = false
      }
    }

    const handleWindowBlur = () => {
      kForward.current = false
      kLeftward.current = false
      kBackward.current = false
      kRightward.current = false
      kSprint.current = false
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)
    window.addEventListener('blur', handleWindowBlur)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('blur', handleWindowBlur)
    }
  }, [])

  // dat.GUI
  // useEffect(() => {
  //   if (bianca_physics_body) {
  //     const newGui = new dat.GUI({ closeFolders: false })
  //     const obj = {
  //       checkBiancaYaw: () => {
  //         // const current_quat = quat(bianca_physics_body.current?.rotation())
  //         // console.log(getYawInDegrees(current_quat))
  //         // console.log(bianca_physics_body.current?.rotation())
  //         // console.log(bianca_physics_body.current?.worldCom())
  //         console.log(bianca_physics_body.current?.translation())
  //         console.log(quat(bianca_physics_body.current?.rotation()))
  //       },
  //     }
  //     newGui.add(obj, 'checkBiancaYaw')
  //   }
  // }, [bianca_physics_body])

  // Manage the current and target yaw
  useEffect(() => {
    if (!bianca_physics_body.current) return
    if (!currentInteractionLocationObj) {
      return setPlayerLocationObj(null)
    }

    const bianca_position = bianca_physics_body.current.worldCom()
    const bianca_position_vector = new THREE.Vector3(bianca_position.x, bianca_position.y, bianca_position.z)

    const current_yaw = getYawInDegrees(quat(bianca_physics_body.current.rotation()))

    const yawToTarget = determineYawToPointTo(
      currentInteractionLocationObj.world_position,
      bianca_position_vector
    )

    spin_to_face_interact_clockwise.current = shouldSpinYawClockwise(current_yaw, yawToTarget)
    target_yaw.current = yawToTarget

    // Handle cases in which Bianca gets stuck due to rotating very close to a body,
    // but without touching it (hence can_rotate_in_place was initially true)
    spin_in_place_stuck_timeout.current = setTimeout(() => {
      can_rotate_in_place.current = false
      bianca_physics_body.current?.setEnabledTranslations(true, true, true, true)
    }, MAX_FULL_SPIN_IN_PLACE_TIME)

    spin_total_timeout.current = setTimeout(() => {
      if (!document.hasFocus()) return
      target_yaw.current = null
    }, MAX_FULL_SPIN_TIME)
  }, [currentInteractionLocationObj, setPlayerLocationObj])

  // Trigger animations on interactions of animation type
  // and set bianca position when talking to her
  useEffect(() => {
    if (!bianca_physics_body.current) return
    if (!currentInteractionObj) {
      current_idle_animation.current = PRIMARY_IDLE_ANIMATION

      if (currentInteractionLocationObj?.id === BIANCA_ID) {
        setCurrentInteractionLocation(null)
      }

      return
    }

    const { currentInteraction, currentlyInspecting } = currentInteractionObj
    bianca_physics_body.current.setLinvel({ x: 0, y: 0, z: 0 }, true)

    if (currentlyInspecting.id === BIANCA_ID) {
      const bianca_position = bianca_physics_body.current.worldCom()
      const bianca_position_vector = new THREE.Vector3(
        bianca_position.x + 0.0,
        bianca_position.y,
        bianca_position.z + 0.05
      )

      setCurrentInteractionLocation({
        id: BIANCA_ID,
        world_position: camera.position,
      })

      interactable_bianca_objects.bianca_rig.position = bianca_position_vector
    }

    if (currentlyInspecting.on_inspect_animation) {
      current_idle_animation.current = currentlyInspecting.on_inspect_animation
      clearIdleAnimationTimeout()
    }

    if (currentInteraction.type !== InteractionTypeEnum.animation) return

    if (currentlyInspecting.id === 'jump_spring_armature' && !isMidair()) {
      jump(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInteractionObj])

  // Save last bianca position when we go into a world or inside the mushroom house
  // It must be emitted right before setting a job project and not on Bianca's unmount
  // so that we can set the default exit position on a project when moving with the
  // browser's navigation arrow keys
  useEffect(() => {
    const saveLastBiancaPosition = (save_to: PositionToSave) => {
      if (!bianca_physics_body.current) return

      if (save_to === 'world') {
        const bianca_position = bianca_physics_body.current.translation()
        const bianca_position_vector = new THREE.Vector3(
          bianca_position.x,
          bianca_position.y,
          bianca_position.z
        )

        const bianca_rotation = quat(bianca_physics_body.current.rotation())

        setLastBiancaLocation({
          position: bianca_position_vector,
          rotation: bianca_rotation,
        })
      } else {
        const door_position = interactable_world_objects.house_door.position
        if (!door_position) return

        const outside_house_rotation = new THREE.Quaternion()
        const outside_house_position = new THREE.Vector3(
          door_position.x - 1.5,
          lastBiancaLocation.position.y,
          door_position.z + 6
        )

        setLastBiancaLocation({
          position: outside_house_position,
          rotation: outside_house_rotation,
        })
      }
    }
    emitter.on('save_bianca_location', saveLastBiancaPosition)

    return () => {
      emitter.off('save_bianca_location')
    }
  }, [insideHouse, lastBiancaLocation, setBiancaLoaded, setLastBiancaLocation, currentJobProject])

  // DISPOSE OBJECTS ON UNMOUNT
  useEffect(() => {
    return () => {
      if (!biancaLoaded) return
      setBiancaLoaded(false)
      setPlayerLocationObj(null)
      sceneChildren.forEach((child) => deepDispose(child.object))
      bianca_physics_body.current = null
    }
  }, [sceneChildren, biancaLoaded, setBiancaLoaded, setPlayerLocationObj, world])

  const getYawInDegrees = (quaternion: THREE.Quaternion) => {
    // The rotation order matters a lot here. Use a different combination and you'll get inconsistent Y results.
    // When dealing with character or camera orientation, the yaw-pitch-roll convention is commonly used: Using 'YXZ'
    // aligns with the intuitive understanding of first rotating the character around the Y-axis to face a direction (yaw),
    // then adjusting the look up or down (pitch), and lastly applying any roll.
    const euler = new THREE.Euler().setFromQuaternion(quaternion, 'YXZ')
    let yaw = THREE.MathUtils.radToDeg(euler.y)

    // Convert yaw to the desired range (otherwise it'd be -180 to 180)
    if (yaw < 0) {
      yaw += 360
    }

    return yaw
  }

  const targetYawSatisfied = () => {
    if (!bianca_physics_body.current || target_yaw.current === null) return true

    const t_yaw = target_yaw.current
    const current_yaw = getYawInDegrees(quat(bianca_physics_body.current.rotation()))
    const offset = 4
    const target_yaw_low = t_yaw - offset < 0 ? t_yaw - offset + 360 : t_yaw - offset
    const target_yaw_high = t_yaw - offset > 359 ? t_yaw + offset - 360 : t_yaw + offset

    const wrap_around = target_yaw_low > target_yaw_high

    if (
      (!wrap_around && current_yaw > target_yaw_low && current_yaw < target_yaw_high) ||
      (wrap_around && (current_yaw >= target_yaw_low || current_yaw <= target_yaw_high))
    ) {
      // Right after we finish rotating, let the interacting object he can rotate now if he isn't the player
      if (
        !playerLocationObj &&
        currentInteractionLocationObj &&
        currentInteractionLocationObj.id !== BIANCA_ID
      ) {
        const world_vec = bianca_physics_body.current.worldCom()

        setPlayerLocationObj({
          id: BIANCA_ID,
          world_position: new THREE.Vector3(world_vec.x, world_vec.y, world_vec.z),
        })
      }

      if (spin_in_place_stuck_timeout.current) {
        clearTimeout(spin_in_place_stuck_timeout.current)
        spin_in_place_stuck_timeout.current = null
      }

      if (spin_total_timeout.current) {
        clearInterval(spin_total_timeout.current)
        spin_total_timeout.current = null
      }

      target_yaw.current = null
      return true
    }

    return false
  }

  const cameraFollowMe = (camera: THREE.Camera) => {
    if (!bianca_physics_body.current) return

    const body_position = bianca_physics_body.current.translation()
    const camera_position = new THREE.Vector3().copy(body_position)
    const camera_target = new THREE.Vector3().copy(body_position)

    const body_approaching_world_end_z = insideHouse ? 2.5 : 25

    if (body_position.z > body_approaching_world_end_z) {
      camera_position.z = body_approaching_world_end_z
      camera_target.z = body_approaching_world_end_z
    }

    camera_position.y += 14.44
    camera_position.z += 25.85

    // Focus on interactable objects
    // loadingScreenClosed is different from loading because it's set to true after the
    // screen-closing animation plays. This prevents the camera from un-focusing right
    // after the loading is triggered when we confirm we want to go into a world.
    if (
      interactableObject?.position &&
      interactableObject.focus_on_contact &&
      !loadingScreenClosed &&
      can_focus_on_interactable.current
    ) {
      const camera_target = new THREE.Vector3().copy(interactableObject.position)
      smoothed_camera_target.current.lerp(camera_target, 0.05)

      // Some interactable objects that have a focus on contact also move the camera position a bit
      if (interactableObject.camera_position_offset) {
        const y_limit = 2.5
        const threshold = 0.01
        const target_position =
          interactCameraPosition ||
          new THREE.Vector3()
            .copy(smoothed_camera_position.current)
            .add(interactableObject.camera_position_offset)

        if (target_position.y < y_limit) target_position.y = y_limit

        if (
          !interactCameraPosition ||
          smoothed_camera_position.current.distanceTo(target_position) > threshold
        ) {
          setInteractCameraPosition(target_position)
          smoothed_camera_position.current.lerp(target_position, 0.05)
          camera.position.copy(smoothed_camera_position.current)
        }
      }

      return camera.lookAt(smoothed_camera_target.current)
    }

    // Focus on the interactable object when an interaction with "focus on start" is triggered
    if (
      currentInteractionObj &&
      currentInteractionObj.currentInteraction.type === InteractionTypeEnum.dialog &&
      currentInteractionObj.currentInteraction.focus_on_start
    ) {
      const interacting_obj_position = currentInteractionObj.currentlyInspecting.position

      if (interacting_obj_position) {
        const current_position = bianca_physics_body.current.worldCom()
        const current_position_vector = new THREE.Vector3(
          current_position.x,
          current_position.y,
          current_position.z
        )
        const intermediate_point = new THREE.Vector3()
          .copy(current_position_vector)
          .lerp(interacting_obj_position, 0.5)

        const zoom_vector = new THREE.Vector3(-1, -7, -13)
        const camera_target = new THREE.Vector3().copy(intermediate_point)
        smoothed_camera_target.current.lerp(camera_target, 0.05)

        const zoomed_in_position =
          interactCameraPosition ||
          new THREE.Vector3().copy(smoothed_camera_position.current).add(zoom_vector)

        if (!interactCameraPosition) {
          setInteractCameraPosition(zoomed_in_position)
        }

        smoothed_camera_position.current.lerp(zoomed_in_position, 0.05)
        camera.position.copy(smoothed_camera_position.current)

        return camera.lookAt(smoothed_camera_target.current)
      }
    }

    if (!interactableObject && !currentInteractionObj && interactCameraPosition) {
      setInteractCameraPosition(null)
    }

    smoothed_camera_position.current.lerp(camera_position, 0.05)
    // Determines if Bianca will look shaky/jittery when moving fast.
    // Larger values = more jittery. Smaller values: less jittery but
    // the camera takes longer to face you, a good balance is under 0.3
    smoothed_camera_target.current.lerp(camera_target, 0.05)

    camera.position.copy(smoothed_camera_position.current)
    camera.lookAt(smoothed_camera_target.current)
  }

  const handleJumpData = (e: CollisionEnterPayload) => {
    if (!bianca_physics_body.current || !e.colliderObject) return

    const currently_midair = isMidair(e.colliderObject.name)
    if (!currently_midair) {
      is_midair.current = false

      if (!currentInteractionObj) {
        setBiancaBeingAnimated(false)
      }
    }

    if (currentInteractionObj) {
      const { currentInteraction, currentlyInspecting } = currentInteractionObj

      if (
        currentInteraction.type === InteractionTypeEnum.animation &&
        currentlyInspecting.id === 'jump_spring_armature'
      ) {
        if (!currently_midair) {
          setBiancaBeingAnimated(false)
          setCurrentInteractionObj(null)
        }
      }
    }

    if (e.colliderObject?.name === 'jump_spring_armature') {
      const bianca_y = bianca_physics_body.current.worldCom().y
      const buggy_jump = bianca_y >= 2 && bianca_y < 2.8
      const normal_jump = bianca_y >= 3.5

      if (buggy_jump) {
        emitter.emit('play_animation', 'jump_spring_animation')
        bianca_physics_body.current.applyImpulse({ x: 600, y: 0, z: 0 }, true)
      }

      if (normal_jump) {
        emitter.emit('play_animation', 'jump_spring_animation')
        changeAction('bianca_mid_jump')
        jump(false, 2000)
      }
    }
  }

  const handleCollisionEnter = (e: CollisionEnterPayload) => {
    if (!bianca_physics_body.current || !e.colliderObject) return

    if (e.colliderObject.name !== 'floor' && !currentInteractionObj) {
      can_rotate_in_place.current = false
    }

    handleJumpData(e)

    if (small_obstacles[e.colliderObject.name]) {
      const bianca_y = bianca_physics_body.current.translation().y
      const obstacle_y = e.colliderObject.position.y

      if (bianca_y < obstacle_y) {
        setObstacleToWalkOver(e.colliderObject)
      }
    }
  }

  const handleCollisionExit = (e: CollisionExitPayload) => {
    if (!bianca_physics_body.current || !e.colliderObject) return

    if (!currentInteractionObj) {
      can_rotate_in_place.current = true
    }

    if (small_obstacles[e.colliderObject.name]) {
      setObstacleToWalkOver(null)
    }
  }

  const clearIdleAnimationTimeout = () => {
    if (idle_animation_timeout.current) {
      clearTimeout(idle_animation_timeout.current)
      idle_animation_timeout.current = null
    }
  }

  useFrame((state, delta) => {
    if (!bianca_physics_body.current) return

    if (loading || !gameStarted) {
      return cameraFollowMe(state.camera)
    }

    const { mForward, mBackward, mLeftward, mRightward } = activeMobileDirectionsObj

    const forward = kForward.current || mForward
    const backward = kBackward.current || mBackward
    const leftward = kLeftward.current || mLeftward
    const rightward = kRightward.current || mRightward
    const sprint = kSprint.current || activeMobileSprint

    const no_keys_pressed = !forward && !backward && !leftward && !rightward
    const in_dialog = currentInteractionObj?.currentInteraction.type === InteractionTypeEnum.dialog
    const currentClip = currentAction?.getClip()

    if (currentClip?.name === 'bianca_sliding_down' && animatingSlide) {
      bianca_physics_body.current.applyImpulse({ x: -5, y: 0, z: 0 }, true)
    }

    if (
      (no_keys_pressed || (backward && forward) || (leftward && rightward) || in_dialog) &&
      !animatingSlide
    ) {
      if (target_yaw.current && !targetYawSatisfied() && document.hasFocus()) {
        changeAction('bianca_walk')
        bianca_physics_body.current.setEnabledRotations(false, true, false, true)
        let spin_power = 800

        if (
          can_rotate_in_place.current &&
          currentInteractionObj?.currentlyInspecting.rotate_in_place_when_facing
        ) {
          spin_power = 400
          bianca_physics_body.current.setEnabledTranslations(false, false, false, true)
        }

        const spin_direction = spin_to_face_interact_clockwise.current ? -spin_power : spin_power
        const spin_y = spin_direction * delta

        bianca_physics_body.current.applyTorqueImpulse(new THREE.Vector3(0, spin_y, 0), true)
      } else if (!biancaBeingAnimated) {
        bianca_physics_body.current.setEnabledTranslations(true, true, true, true)
        bianca_physics_body.current.setEnabledRotations(false, false, false, true)

        // This if statement serves as a safety net since the next one seems to execute
        // even if there is a currentInteractionObj without an on_inspect_animation
        // Not even checking for a currentInteractionObj inside the timeout works,
        // So I made this an if else statement
        if (currentInteractionObj && !currentInteractionObj.currentlyInspecting.on_inspect_animation) {
          if (idle_animation_timeout.current) {
            clearIdleAnimationTimeout()
          }

          current_idle_animation.current = PRIMARY_IDLE_ANIMATION
        } else if (
          !idle_animation_timeout.current &&
          current_idle_animation.current !== SECONDARY_IDLE_ANIMATION &&
          !currentInteractionObj?.currentlyInspecting.on_inspect_animation
        ) {
          idle_animation_timeout.current = setTimeout(() => {
            current_idle_animation.current = SECONDARY_IDLE_ANIMATION
          }, 6000)
        }

        changeAction(current_idle_animation.current)
      }

      return cameraFollowMe(state.camera)
    }

    clearIdleAnimationTimeout()
    current_idle_animation.current = PRIMARY_IDLE_ANIMATION

    let vel_strength = sprint ? 1500 : 1000
    if (insideHouse) vel_strength = sprint ? 1300 : 750

    if (is_midair.current && vel_strength > MAX_MIDAIR_VEL) vel_strength = MAX_MIDAIR_VEL
    if (animatingSlide) vel_strength = 500
    const vel_obstacle_overcome_strength = sprint ? 1900 : 1500
    const torque_strength = sprint ? 1400 : 1100

    const body_rotation = quat(bianca_physics_body.current.rotation())
    const yaw_direction = getYawInDegrees(body_rotation)

    const looking_forward = yaw_direction >= 177 && yaw_direction <= 183
    const looking_backward = yaw_direction >= 357 || yaw_direction <= 3
    const looking_leftward = yaw_direction >= 265 && yaw_direction <= 275
    const looking_rightward = yaw_direction >= 85 && yaw_direction <= 95

    const looking_backward_right = yaw_direction >= 40 && yaw_direction <= 50
    const looking_forward_right = yaw_direction >= 130 && yaw_direction <= 140
    const looking_backward_left = yaw_direction >= 310 && yaw_direction <= 320
    const looking_forward_left = yaw_direction >= 220 && yaw_direction <= 230

    if (looking_forward) lookingDirection.current = 'forward'
    if (looking_backward) lookingDirection.current = 'backward'
    if (looking_leftward) lookingDirection.current = 'leftward'
    if (looking_rightward) lookingDirection.current = 'rightward'
    if (looking_backward_right) lookingDirection.current = 'backward_right'
    if (looking_forward_right) lookingDirection.current = 'forward_right'
    if (looking_backward_left) lookingDirection.current = 'backward_left'
    if (looking_forward_left) lookingDirection.current = 'forward_left'

    if (forward && (leftward || rightward)) {
      frame_vel.current.z -= vel_strength / 1.2
    } else if (forward) {
      frame_vel.current.z -= vel_strength
    }

    if (backward && (leftward || rightward)) {
      frame_vel.current.z += vel_strength / 1.2
    } else if (backward) {
      frame_vel.current.z += vel_strength
    }

    if (leftward && (forward || backward)) {
      frame_vel.current.x -= vel_strength / 1.2
    } else if (leftward) {
      frame_vel.current.x -= vel_strength
    }

    if (rightward && (forward || backward)) {
      frame_vel.current.x += vel_strength / 1.2
    } else if (rightward) {
      frame_vel.current.x += vel_strength
    }

    // Negative torque: clockwise rotation (left) - Positive: counterclockwise rotation (right)
    const directions = [
      {
        direction_buttons: forward && rightward,
        direction_active: looking_forward_right,
        clockwise_directions: ['backward_left', 'leftward', 'forward_left', 'forward'],
      },
      {
        direction_buttons: forward && leftward,
        direction_active: looking_forward_left,
        clockwise_directions: ['backward', 'backward_left', 'leftward'],
      },
      {
        direction_buttons: backward && rightward,
        direction_active: looking_backward_right,
        clockwise_directions: ['forward_left', 'forward', 'forward_right', 'rightward'],
      },
      {
        direction_buttons: backward && leftward,
        direction_active: looking_backward_left,
        clockwise_directions: ['backward', 'backward_right', 'rightward', 'forward_right'],
      },
      {
        direction_buttons: forward,
        direction_active: looking_forward,
        clockwise_directions: ['backward', 'backward_left', 'leftward', 'forward_left'],
      },
      {
        direction_buttons: backward,
        direction_active: looking_backward,
        clockwise_directions: ['forward', 'forward_right', 'rightward', 'backward_right'],
      },
      {
        direction_buttons: leftward,
        direction_active: looking_leftward,
        clockwise_directions: ['rightward', 'backward_right', 'backward', 'backward_left'],
      },
      {
        direction_buttons: rightward,
        direction_active: looking_rightward,
        clockwise_directions: ['leftward', 'forward_left', 'forward', 'forward_right'],
      },
    ]

    for (const dir of directions) {
      if (dir.direction_buttons) {
        bianca_physics_body.current.setEnabledRotations(false, true, false, true)

        if (dir.direction_active) {
          // block applyImpulse's small torque by locking the rotations
          bianca_physics_body.current.setAngvel({ x: 0, y: 0, z: 0 }, true)
          bianca_physics_body.current.setEnabledRotations(false, false, false, true)
        } else {
          const rotateClockwise = dir.clockwise_directions.includes(lookingDirection.current)
          frame_rotation.current.y += rotateClockwise ? -torque_strength : torque_strength
        }

        if (obstacleToWalkOver) {
          const bianca_y = bianca_physics_body.current.translation().y
          const obstacle_y = obstacleToWalkOver.position.y

          if (bianca_y < obstacle_y) {
            bianca_physics_body.current.setTranslation(
              {
                ...bianca_physics_body.current.translation(),
                y: obstacle_y + small_obstacles[obstacleToWalkOver.name],
              },
              true
            )
            frame_vel.current.y += vel_obstacle_overcome_strength
          }
        }

        break
      }
    }

    if (!biancaBeingAnimated && !animatingSlide) {
      if (sprint) {
        changeAction('bianca_run')
      } else {
        changeAction('bianca_walk')
      }
    }

    frame_vel.current.multiplyScalar(delta)
    frame_rotation.current.multiplyScalar(delta)

    bianca_physics_body.current.applyImpulse(frame_vel.current, true)
    bianca_physics_body.current.applyTorqueImpulse(frame_rotation.current, true)

    // CAMERA
    cameraFollowMe(state.camera)
  })

  if (!sceneChildren.length) return null

  return (
    <RigidBody
      name="bianca"
      ref={bianca_physics_body}
      type="dynamic"
      colliders={false}
      density={3.2}
      linearDamping={2.5}
      angularDamping={10}
      gravityScale={3}
      enabledRotations={[false, true, false]}
      onCollisionEnter={handleCollisionEnter}
      onCollisionExit={handleCollisionExit}
    >
      <CuboidCollider name="bianca" args={[1, 1.2, 1]} position={sceneChildren[0].object.position} />
      {sceneChildren.map((child) => child.element)}
    </RigidBody>
  )
}

export default Bianca

useGLTF.preload('/models/portfolio_forest/bianca.glb')
